// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$sgq-croma-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$sgq-croma-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$sgq-croma-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$sgq-croma-theme: mat.m2-define-light-theme((color: (primary: $sgq-croma-primary,
        accent: $sgq-croma-accent,
        warn: $sgq-croma-warn,
      ),
      typography: mat.m2-define-typography-config(),
      density: 0));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($sgq-croma-theme);
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import "./app/variaveis.scss";

$primary-color: #1d2334;
$secudary-color: #f5f5f5;
// $bg-color-primary:#1b42bd ;



* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  // color: $texto-preto;
  a{
    display: block;
    text-decoration: none;
  }
}


main {
  height: 100vh;
}

.modal-class {
  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 650px;
}

.card-estilo {
  color: #EEEEEE;
  padding: 10px 20px;
  background: $bg-color-primary ;
}



html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  // height: 100%;
  background-color: #EEEEEE;
  --mdc-snackbar-container-color: none;
}


body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

input {
  font-size: $textoComum;
}

.my-snack-bar-erro {
  border-radius: 5px;
  background-color: red;

  label {
    color: white;
  }
}

.my-snack-bar-sucess {
  border-radius: 5px;
  background-color: $bg-cores-destaque-terceary;

  label {
    color: white;
  }
}

.my-snack-bar-warning {
  border-radius: 5px;
  background-color: rgb(207, 159, 26);

  label {
    color: white;
  }
}


.cdk-overlay-container {
  z-index: 100000 !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  border-radius: 0px !important;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  background: black !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
  color: $texto-preto !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
}

.mat-drawer-inner-container {
  height: inherit !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}





html ::-webkit-scrollbar {
  width: 5px;
}

html ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: $bg-color-primary;
}

html ::-webkit-scrollbar-track {
  background: #EEEEEE;
}

.mat-expansion-panel-header-description {
  margin-right: 0 !important;
}


/* Remove spinners em navegadores Webkit (Chrome, Safari, etc.) */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove spinners em navegadores Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Estilo personalizado (opcional) */
input[type='number'] {
  appearance: textfield;
  /* Remove spinners em navegadores que suportam 'appearance' */
}

.fechado {
  display: none;
}

.aberto {
  display: block;
}

@keyframes aparecer {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

}

//EDITOR QUILL
.ql-editor {
  background-color: #fff !important;

}

.ql-toolbar.ql-snow {
  background-color: #f8f8f8;
  border-radius: 5px 5px 0px 0px !important;
}

.pointer {
  cursor: pointer;
}

.mat-expansion-panel-body {
  padding: 8px !important;
}


.custom-dialog-container .mat-dialog-container {
  padding: 0;
  width: auto;
  height: auto;
  max-width: 90vw;
  max-height: 90vh;
  overflow: hidden;
  /* Evita scroll dentro do modal */
}

.mat-dialog-content {
  padding: 0;
  /* Remove padding padrão do conteúdo do modal */
}


.mat-step-icon {
  background-color: $bg-color-botao-sucess !important;
}

.mat-step-icon-state-done {
  background-color: $bg-cores-destaque-terceary-hover !important;
}

.mat-step-icon-state-error {
  background-color: #ffffff00 !important;
}

.mat-expansion-panel-header-description {
  margin-right: 5px !important;
}

.mat-step-label {
  white-space: wrap !important;
  overflow: auto !important;
  text-overflow: initial !important;
}

.mat-mdc-button {
  background-color: $bg-color-botao-sucess !important;
  color: $secudary-color !important;
}

.mat-mdc-tab-header {
  background-color: #fff !important;
  padding: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 5px 20px 1px rgba(148, 148, 148, 1);
  -moz-box-shadow: 0px 5px 20px 1px rgba(148, 148, 148, 1);

  .mdc-tab__text-label {
    color: #086586;
    /* Cor ativa da aba */
    font-weight: 700;
  }
}



@media screen and (max-width:800px) {
  .mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination {
    display: none !important;
  }
  .mat-mdc-tab-list{
    transform: translateX(0px ) !important;
  }
  .mat-mdc-tab-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto !important;
    -webkit-overflow-scrolling: touch !important;
    scroll-snap-type: x mandatory;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
  }
}



@media screen and (max-width:700px) {

  .mat-mdc-tab-header {
    margin-left: 0px;
    margin-right: 0px;
  }

  .cdk-overlay-pane.mat-mdc-dialog-panel {
    width: 100vw !important;
    max-width: 100vw !important;
  }

  .mat-vertical-stepper-header {
    padding: calc((var(--mat-stepper-header-height) - 24px) / 2) 0px !important;
  }

  .mat-vertical-content-container {
    margin-left: 12px !important;
  }

  .modal-class {
    width: 100vw;
    height: 100%;

    max-height: 100vh;
  }


}


.c-pointer {
  cursor: pointer;
}


// /* Custom transition */
// @keyframes rotate-out {
//   to {
//     transform: rotate(90deg);
//   }
//  }
//  @keyframes rotate-in {
//   from {
//     transform: rotate(-90deg);
//   }
//  }
//  ::view-transition-old(count),
//  ::view-transition-new(count) {
//   animation-duration: 200ms;
//   animation-name: -ua-view-transition-fade-in, rotate-in;
//  }
//  ::view-transition-old(count) {
//   animation-name: -ua-view-transition-fade-out, rotate-out;
//  }




//  @keyframes fade-in{
//   from{
//     opacity: 100%;
//   }
// }

// @keyframes fade-out{
//   to{
//     opacity: 0;
//   }
// }

// @keyframes slide-right{
//   from{
//     transform: translateY(-50%);
//   }
// }

// @keyframes slide-left{
//   to{
//     transform: translateY(50%);
//   }
// }

// ::view-transition-old(root){
//   animation: 0.2 fade-out, 0.2s slide-left;
// }
// ::view-transition-new(root){
//   animation: 0.5s fade-in, 0.5s slide-right;
// }