//cores
$primary-color: #333333;
$secudary-color: #FDFDFD;
//cor antiga
// $bg-color-primary:#131720 ;
$bg-color-primary: #2b4e7e;
// $bg-color-primary: linear-gradient(180deg, $bg-color-primary,#15446b,#174d7a, #2a6694, ); ;
$bg-color-secondary: #121e38;
$bg-color-botao-sucess-claro: #086586;
$bg-color-botao-sucess: #06475f;
$bg-color-botao-sucess-hover: #044c6d;
$bg-color-botao-borda-sucess: #EBF5FF;
$bg-color-botao-error: #BC3024;
$bg-color-botao-error-hover: #7c0000;
$bg-cores-destaque-terceary: #3EB75E;
$bg-cores-destaque-terceary-hover: #21803a;
$bg-color-primary-menu: #ffffff;


$altura-descontando-header-footer:calc(100dvh - 90px);

$verde-novo:#48C37C;


$cor-nova :#263035;

$cor-nova :#3D4B64;
$cor-mais-forte-cima:#303C54;

//Variaveis para tamanho de texto;
$titulo: 1.563rem;
$subTitulo: 1.375rem;
$textoMedio: 1.25rem;
$textoDestaque: 1.063rem;
$textoComum: 1rem;
$textoPequeno: 0.800rem;
$textoHiperPequeno: 0.750rem;
//ICONE
$iconeMedio: 20px;
$iconeGrande: 40px;

//para cores de texto
$texto-opaco: #AAAFB7;
$texto-preto: #232323;



// $bg-color-primary:#A1B7CC ;
// $bg-color-primary:#CDD9E5 ;
// $bg-color-primary:#F2F3F7 ;
// $bg-color-primary:#CFD5E6 ;
// $bg-color-primary:#F0F2F5 ;
// $bg-color-primary:#38A3FF ;
// $bg-color-bgdestaque:#EBF5FF ;
// $bg-color-bgdestaque:#4998D6 ;
// $bg-color-bgdestaque:#EBF5FF ;
// $bg-cores-destaque:#ec6f2b ;
// $bg-cores-bgdestaque:#F9EBE3 ;
// $bg-cores-bgdestaque-terceary:#EBF8EF ;
// $bg-cores-destaque-secundary:#845CEC ;
// $bg-cores-bgdestaque-secundary:#F4EFFF;